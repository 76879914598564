import Table from 'react-bootstrap/Table'
import React, { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import { getUsersByClass } from '../../services/bffServices/getUserDetails';
import ReactExport from "react-export-excel";
import { updateUserAddClass } from '../../services/bffServices/updateUser';
import Placeholder from 'react-bootstrap/Placeholder';
import { getClassListTeachers } from '../../services/bffServices/getClassesByTeacher';
import Spinner from 'react-bootstrap/Spinner'
import { convertUtcToLocal } from '../../../src/utls/lib';
import { forceSyncGrades } from '../../services/quiz/ltiSync';
import xml2js from 'react-native-xml2js';

// import { View, StyleSheet } from 'react-native';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

var dataSet1 = [];

function StudentList(props) {
  const [checked, setChecked] = useState(false);
  const [buttonNameLTI, setButtonNameLTI] = useState("Date Enforced");
  const [buttonTypeLTI, setButtonTypeLTI] = useState("outline-primary");
  const [studentList, setStudentList] = useState();
  const [classSelected, setClassSelected] = useState();
  const [schoolList, setSchoolList] = useState();
  const [showLoading, setShowLoading] = useState(false);
  const [exceptionUpdateStudent, setExceptionUpdateStudent] = useState();
  const [exceptionExpiryDate, setExceptionExpiryDate] = useState();
  const [exceptionUpdateType, setExceptionUpdateType] = useState();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showDetails, setShowDetails] = useState(false);
  const handleCloseDetails = () => setShowDetails(false);
  const handleShowDetails = () => setShowDetails(true);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [activeDataSet, setActiveDataSet] = useState([{}]);
  const [forceGradeSyncStudentId, setForceGradeSyncStudentId] = useState();
  const [forceGradeSyncStudentDetails, setForceGradeSyncStudentDetails] = useState([{}]);
  const [showSyncSpinner, setShowSyncSpinner] = useState(false);



  //gradeSyncModal
  const [showSync, setShowSync] = useState(false);
  const handleSyncClose = () => setShowSync(false);
  const handleSyncShow = () => setShowSync(true);

  let pageSize = 10;


  const modalChange = (username, type) => {

    setExceptionUpdateStudent(username);
    setExceptionUpdateType(type);
    handleShow();

  }

  // const forceGradeSyncProcessor = (studentId) => {

  //   // debugger;
  //   console.log("forceGradeSyncStudentId" + JSON.stringify(studentId))
  //   console.log("forceGradeSyncStudentId" + JSON.stringify(forceGradeSyncStudentId))
  //   // var forceGradeResults = await forceSyncGrades(forceGradeSyncStudentId);
  //   // console.log("forceGradeSyncProcessor" + JSON.stringify(forceGradeResults));

  // }

  const currentTableData = async (quizList, currentPage) => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    setActiveDataSet(quizList.slice(firstPageIndex, lastPageIndex));
  };

  var pageinationBar = () => {

    var html = [];

    for (var i = 1; i <= numberOfPages; i++) {
      html.push(<button class="page-link" onClick={event => updatePage(event.target.value, studentList)} value={i}> {i} </button>);
    }

    return (
      <>
        {html}
      </>
    )
  }

  var updatePage = async (num, orders) => {

    currentTableData(orders, num);
    var numPages = orders.length / 10;
    await setNumberOfPages(Math.round(numPages));
  }

  const createDownloadDataSet = async (data) => {

    var newData = [];
    var summaryData = [];
    var gradeTotal = 0;
    var numQuiz = 0;
    for (var i = 0; i < data.length; i++) {
      if (data[i].grades) { // add this check
        for (var g = 0; g < data[i].grades.length; g++) {
          if (data[i].grades[g].chapters) { // add this check
            for (var c = 0; c < data[i].grades[g].chapters.length; c++) {
              if (data[i].grades[g].chapters[c].modules) { // add this check
                for (var m = 0; m < data[i].grades[g].chapters[c].modules.length; m++) {
                  newData.push({
                    "studentId": data[i].ID,
                    "studentFirstName": data[i].fName,
                    "studentLastName": data[i].lName,
                    "studentEmail": data[i].Email,
                    "unitName": data[i].grades[g]?.unitName,
                    "chapterName": data[i].grades[g].chapters[c]?.chapterName,
                    "moduleName": data[i].grades[g].chapters[c].modules[m]?.moduleName,
                    "grade": data[i].grades[g].chapters[c].modules[m]?.grade || "Not Completed",
                    // "taken": data[i].grades[g].chapters[c].modules[m]?.taken || "",//convertUtcToLocal
                    "taken": await convertUtcToLocal(data[i].grades[g].chapters[c].modules[m]?.taken),///convertUtcToLocal
                    "hasQuiz": data[i].grades[g].chapters[c].modules[m]?.hasQuiz ? "Yes" : "No"
                  });
                  if (data[i].grades[g].chapters[c].modules[m]?.grade != undefined) {
                    gradeTotal = gradeTotal + data[i].grades[g].chapters[c].modules[m]?.grade;
                    // debugger;
                  }

                  if (data[i].grades[g].chapters[c].modules[m]?.hasQuiz == true) {
                    numQuiz = numQuiz + 1;
                  }
                }
                summaryData.push({
                  "studentId": data[i].ID,
                  "studentFirstName": data[i].fName,
                  "studentLastName": data[i].lName,
                  "studentEmail": data[i].Email,
                  "unitName": data[i].grades[g]?.unitName,
                  "chapterName": data[i].grades[g].chapters[c]?.chapterName,
                  // "totalGrade": gradeTotal / data[i].grades[g].chapters[c].modules.length,
                  "totalGrade": ((gradeTotal / numQuiz)).toFixed(2),
                  // "numQuiz": data[i].grades[g].chapters[c].modules.length
                  "numQuiz": numQuiz
                })
                gradeTotal = 0;
                numQuiz = 0;
              }
            }
          }
        }
      }
    }
    var combineData = {
      "dataOne": newData,
      "dataTwo": summaryData
    }
    return combineData;
  }

  const updateStudentException = async (userName, updateType) => {

    setShowLoading(true);
    var exception = "N";
    if (updateType == "ADD") {
      exception = "Y";
    }
    var payload = {
      "userName": userName,
      "updateType": {
        "exceptionChange": updateType
      },
      "exception": exception || "",
      "expireDate": exceptionExpiryDate
    }

    handleClose();
    await updateUserAddClass(payload);
    setStudentList(await getStudentInfo());
    setShowLoading(false);
  }

  const downloadList = () => {

    return (
      <>
        <ExcelFile element={<Button>Download Now</Button>}>
          <ExcelSheet data={dataSet1.dataTwo} name="Student Summary By Chapter">
            <ExcelColumn label="Student ID" value="studentId" />
            <ExcelColumn label="First Name" value="studentFirstName" />
            <ExcelColumn label="Last Name" value="studentLastName" />
            <ExcelColumn label="Email" value="studentEmail" />
            <ExcelColumn label="Unit" value="unitName" />
            <ExcelColumn label="Chapter" value="chapterName" />
            <ExcelColumn label="Grade" value="totalGrade" />
            <ExcelColumn label="# of Quizzes" value="numQuiz" />
          </ExcelSheet>
          <ExcelSheet data={dataSet1.dataOne} name="Full Student Breakdown">
            <ExcelColumn label="Student ID" value="studentId" />
            <ExcelColumn label="First Name" value="studentFirstName" />
            <ExcelColumn label="Last Name" value="studentLastName" />
            <ExcelColumn label="Email" value="studentEmail" />
            <ExcelColumn label="Unit" value="unitName" />
            <ExcelColumn label="Chapter" value="chapterName" />
            <ExcelColumn label="Module" value="moduleName" />
            <ExcelColumn label="Grade" value="grade" />
            <ExcelColumn label="Date" value="taken" />
            <ExcelColumn label="Has a Quiz" value="hasQuiz" />
          </ExcelSheet>
        </ExcelFile>
      </>
    )
  }

  const loadStudentList = async () => {

    setStudentList(() => {
      return (
        <>
          <Placeholder as="p" animation="glow">
            <Placeholder md={12} size="lg" />
            <Placeholder xs={12} size="lg" />
            <Placeholder xs={12} size="lg" />
            <Placeholder xs={12} size="lg" />
            <Placeholder xs={12} size="lg" />
          </Placeholder>
        </>
      )
    })

    setStudentList(await getStudentInfo());
  }

  useEffect(() => {

    // console.log("USE EFFECT forceGradeSyncStudentId updated: ", forceGradeSyncStudentId);
  }, [forceGradeSyncStudentId]);

  const forceGradeSyncProcessor = async (studentId) => {
    if (classSelected == "65956754d98a2100178ba36a"){
      var ltiKey = "old.dominion.u.c.steel"
    }
    await setShowSyncSpinner(true)
    await handleSyncShow();
    await setForceGradeSyncStudentDetails([{}]);
    // setForceGradeSyncStudentId(studentId)
    console.log("assignfForceGradeSync" + JSON.stringify(studentId))
    // console.log("forceGradeSyncStudentId" + JSON.stringify(forceGradeSyncStudentId))
    var forceGradeResults = await forceSyncGrades(studentId, ltiKey);

    // console.log("forceGradeResults" + JSON.stringify(forceGradeResults))


    // for (const details of forceGradeResults.details.syncDetails) {
    for (let i = 0; i < forceGradeResults.details.syncDetails.length; i++) {
      const details = forceGradeResults.details.syncDetails[i];

      // Check if 'syncResults' contains a string (like the HTTP headers)
      if (typeof details.syncResults === 'string') {
        const myString = details.syncResults;
        const xmlStartIndex = myString.indexOf("<?xml");
        const xmlString = myString.substring(xmlStartIndex);

        const parser = new xml2js.Parser();
        parser.parseString(xmlString, (err, result) => {
          if (err) {
            console.error("Error parsing XML for student:", details.studentId, err);
          } else {
            const jsonData = result;
            // console.log("jsonData" + JSON.stringify(jsonData));
            const messageIdentifier = jsonData.imsx_POXEnvelopeRequest.imsx_POXHeader[0].imsx_POXRequestHeaderInfo[0].imsx_messageIdentifier[0];
            console.log("messageIdentifier" + JSON.stringify(messageIdentifier));
            forceGradeResults.details.syncDetails[i].syncId = messageIdentifier;
            console.log("forceGradeResults" + JSON.stringify(forceGradeResults.details.syncDetails[i]))
          }
        });
      } else if (details.syncResults && details.syncResults.msg) {
        // Handle the 'No LTI Content Information found' case
        console.log("No LTI Content for:", details.studentId);
      } else {
        console.warn("Unexpected syncResults format:", details);
      }
    }


    await setForceGradeSyncStudentDetails(forceGradeResults.details.syncDetails);

    await setShowSyncSpinner(false);

  }

  const getStudentInfo = async () => {

    var students = await getUsersByClass(classSelected);
    dataSet1 = await createDownloadDataSet(students);
    return (
      <>
        <>
        {classSelected == "675f286e8f85d1001714b8d0" &&
        <>
<hr />
          <Row>
            <Col>
              <div style={{ textAlign: "left", verticalAlign: "middle" }}><h6>Force LTI Sync</h6></div>
              <table width="100%">
                <tr>
                  <td style={{ width: "50%", textAlign: "left", verticalAlign: "middle" }}>
                    <div style={{ fontSize: "12px" }}>To force an LTI grade sync for a specific student, select their name from the dropdown below. This will update their grades with the latest information to your external learning platform.
                      <br /><br /><strong>Note: </strong>This may overwrite any existing grades in your gradebook.</div>
                  </td>
                  <td style={{ width: "40%", verticalAlign: "middle" }}>

                    <table width="100%">
                      <tr>
                        <td>
                          <Form.Select aria-label="forceGradeSync" id="forceGradeSync"
                            onChange={async (event) => { forceGradeSyncProcessor(event.target.value); }}
                            name="forceGradeSync" >
                            <option value="0"></option>
                            {students.map(student => (
                              <>
                                <option value={student.ID}>{student.fName} {student.lName}</option>
                              </>
                            ))}
                          </Form.Select>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </Col>
          </Row>
        </>
        }
          
        </>
        <hr />
        <Row>
          <Col style={{ 'text-align': "right", width: "25%" }}>
            {/* {downloadList()} <br /><br /> */}
            <div style={{ textAlign: "left", verticalAlign: "middle" }}><h6>Grade Export</h6></div>
            <table width="100%">
              <tr>
                <td style={{ width: "50%", textAlign: "left", verticalAlign: "middle" }}>
                  <div style={{ fontSize: "12px" }}>Click the button below to download a spreadsheet of current class grades. This Excel file is useful for further analysis, record-keeping, or to share with others. The download will start immediately.</div>
                </td>
                <td style={{ width: "40%", verticalAlign: "middle" }}>
                  {downloadList()}
                </td>
              </tr>
            </table><br />
          </Col>
        </Row>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th width="20%">Name</th>
              <th width="30%">Email</th>
              <th>Unit</th>
              <th>Grade</th>
              <th>Extend Deadline</th>
            </tr>
          </thead>
          <tbody>
            {students.map(student => (
              <React.Fragment key={student.ID}>
                {student.grades.map(grade => (
                  <tr key={`${student.ID}-${grade.unitName}`}>
                    <td>{student.fName} {student.lName}</td>
                    <td>{student.Email}</td>
                    <td>{grade.unitName}</td>
                    <td align="center">{grade.unitGrade}%</td>
                    <td align="center">
                      {grade.exceptionAllowed != "Y" &&
                        <Button
                          className="mb-2"
                          type="checkbox"
                          variant={buttonTypeLTI}
                          checked={checked}
                          value="1"
                          onClick={() => modalChange(student.Email, "ADD")}
                        >
                          Add
                        </Button>
                      }
                    </td>
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>

        </Table>
      </>
    )
  }

  const getClassInfo = async () => {
    try {
      const schools = await getClassListTeachers();
      setSchoolList(() => {
        return (
          <>
            {schools.map(classSelected => (
              <>
                <option value={classSelected.schoolId}>{classSelected.schoolName}</option>
              </>
            ))}
          </>
        );
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getClassInfo();

  }, [forceGradeSyncStudentId]);


  return (
    <>
      <Row style={{ width: "50%" }}>
        <Col style={{ 'text-align': "left", margin: "auto" }} >
          Select Your Class:
        </Col>
        <Col style={{ 'text-align': "right" }}>
          <Form.Select aria-label="Default select example" id="newSchool" onChange={event => setClassSelected(event.target.value)} name="newSchool" >
            <option value="0"></option>
            {schoolList}
          </Form.Select>
        </Col>
        <Col style={{ 'text-align': "right" }}>
          <Button onClick={() => loadStudentList()} >Select</Button>
        </Col>
      </Row>

      <br />

      {studentList}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {exceptionUpdateType == "ADD" &&
              <>
                Extend this students deadline?
              </>
            }
            {exceptionUpdateType == "REMOVE" &&
              <>
                Remove tthe extended deadline?
              </>
            }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {exceptionUpdateType == "ADD" &&
            <>
              Are you sure you want to extend this students deadline?<br /><br />
              Select a Date: <br />
              <input type="date" style={{ width: "50%" }} onChange={event => setExceptionExpiryDate(event.target.value)} /> <br /><br />
            </>
          }
          {exceptionUpdateType == "REMOVE" &&
            <>
              Are you sure you want to remove the extended deadline?<br /><br />
            </>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={() => updateStudentException(exceptionUpdateStudent, exceptionUpdateType)}>
            {exceptionUpdateType}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showLoading}
        backdrop="static"
        centered
      >
        <div class="centerLoadingModel" >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      </Modal>
      <Modal show={showSync} onHide={handleSyncClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ 'font-size': "16px" }}>
            LTI Sync Results
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          {showSyncSpinner &&
            <>
              <div style={{ "text-align": "center" }}>
                <Spinner animation="border" role="status" size="lg">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
              <div style={{ 'font-size': "12px", "text-align": "center"  }}>
                Syncing in progress, please wait.  May take 20-30 Seconds
              </div>
            </>
          }
          {!showSyncSpinner &&
            <>
              {forceGradeSyncStudentDetails.map(result => (
                <>
                  {result?.syncError &&
                    <>
                      <div style={{ 'font-size': "12px" }}><strong>{result.grade?.sbContentName}</strong></div> 
                      <div style={{ 'font-size': "12px" }}>Grade: {(result.grade?.averageGrade * 100).toFixed(2) + "%"}</div>
                      <div style={{ 'font-size': "10px", 'color': "#FF0000" }}>User has not accessed via LMS Portal</div>
                      <div style={{ 'font-size': "10px", 'color': "#FF0000" }}><strong>Sync Was NOT Successful</strong></div>
                    </>
                  }
                  {!result?.syncError &&
                    <>
                      <div style={{ 'font-size': "12px" }}><strong>{result.grade?.resourceLinkName}</strong></div>
                      <div style={{ 'font-size': "12px" }}>Grade: {(result.grade?.averageGrade * 100).toFixed(2) + "%"}</div>
                      <div style={{ 'font-size': "10px" }}>LMS Sync ID: {result?.syncId}</div>
                      <div style={{ 'font-size': "10px", 'color': "#00DF00" }}><strong>Sync Successful</strong></div>
                    </>
                  }
                  <hr />
                </>
              ))}
            </>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleSyncClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default StudentList;