import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import { updatePageTitle } from "../../utls/lib";
import CellsSeriesComponent from "./cellsInfoPage";
import LifeSeriesComponent from "./lifeInfoPage";
import FeatureImage from "../../assets/images/Features-graphic.png";
import SleekButton from "../sleekButton";
import { useLocation, Link } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import TestimonialCarousel from "./TestimonialCarousel";
import HighSchoolComponent from "./highschoolInfoPage";
import AnnotatedSlidesIcon from "../../assets/icons/annotated_slides.svg";
import QuizIcon from "../../assets/icons/quiz.svg";
import SchoolIcon from "../../assets/icons/school.svg";
import EmbeddableFiguresIcon from "../../assets/icons/embeddable_figures.svg";
import HighEdPageComponent from "./higherEdInfoPage";

function SubjectsPageComponent(props) {
	useEffect(async () => {
		updatePageTitle("Subjects");
	}, []);

	return (
		<div width="100%" style={{}}>
			<div  className="product-container">
				<Container>
					<Row>
						<Col>
							<Row
								style={{
									"margin-top": "20px",
									marginBottom: "20px",
									maxWidth: "60%",
								}}
							>
								<h1 className="catalogueHeader">CLASSROOM SOLUTIONS</h1>
								<div
									style={{
										display: "flex",
										alignContent: "center",
										alignItems: "center",
									}}
								>
									<h3
										style={{
											color: "white",
											opacity: "0.7",
											paddingRight: "12px",
										}}
									>
										Starting as low as $25 per student
									</h3>
									<a href="/trial">
										<button className="trial-button">INSTRUCTOR TRIAL</button>
									</a>
								</div>
							</Row>
						</Col>
					</Row>
				</Container>
			</div>

			<div
				style={{
					marginTop: "60px",
					paddingTop: "10px",
					paddingBottom: "10px",
					width: "80%",
					margin: "0 auto", // Center horizontally
					textAlign: "left",
					fontSize: "14px",
				}}
			>
				<div style={{ marginTop: "60px" }}>
					<Tabs />
				</div>
			</div>

			<div
				style={{
					textAlign: "left",
					backgroundColor: "#f6f7f8",
					paddingBottom: "30px",
				}}
			>
				<Container>
					<h1
						align={"center"}
						style={{ padding: "4%", fontSize: "54px", color: "#5a5a5a" }}
					>
						Take A Look Inside.
					</h1>

					<h1 style={{ fontWeight: "bold" }}></h1>
					<div
						style={{
							display: "flex",
							alignContent: "center",
							justifyContent: "center",
						}}
					>
						<img width="90%" src={FeatureImage}></img>
					</div>

					<h2 style={{ fontWeight: "bold", textAlign: "center" }}>
						Additional instructor resources available
					</h2>
					<br />
					<br />
					<div
						style={{
							display: "flex",
							flexDirection: "horizontal",
							justifyContent: "center",
						}}
					>
						<div style={{display: 'flex'}}>
							<h4>
								<ul className="feature_list">
									<li>
										<img className="feature_icon" src={AnnotatedSlidesIcon} />{" "}
										Annotated Slides
									</li>
									<li>
										<img className="feature_icon" src={EmbeddableFiguresIcon} />{" "}
										Embeddable Figures
									</li>
									
								</ul>
							</h4>
							<h4>
								<ul className="feature_list">
								<li>
										<img className="feature_icon" src={QuizIcon} /> Instructor
										Dashboard
									</li>
									<li>
										<img className="feature_icon" src={SchoolIcon} />
										High-school Teacher Guide
									</li>
									
								</ul>
							</h4>
						</div>
						<div></div>
					</div>
				</Container>
			</div>
			<div
				style={{
					textAlign: "left",

					paddingBottom: "30px",
				}}
			>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<h1
						align={"center"}
						style={{ padding: "4%", fontSize: "54px", color: "#5a5a5a" }}
					>
						What our learners have to say.
					</h1>
					<div
						style={{
							textAlign: "left",
						}}
					>
						<TestimonialCarousel></TestimonialCarousel>
					</div>
				</div>
			</div>

			<div
				style={{
					"margin-top": "15px",
					"padding-top": "10px",
					"padding-bottom": "10px",
					"text-align": "left",
					"font-size": "10px",
				}}
			>
				<Container>
					<div
						className="d-grid"
						style={{ "padding-right": "60px", "padding-left": "60px" }}
					>
						<br />
					</div>
				</Container>
			</div>
		</div>
	);
}

function Tabs() {
	const location = useLocation();

	const tabs = [
		{
			title: "Higher ED",
			url: "highered",
			content: <HighEdPageComponent />,
		},
		{
			title: "High School",
			url: "highschool",
			content: <HighSchoolComponent />,
		}
	];

	const activeTab =
		location.pathname.split("/").pop() === "subjects"
			? tabs[0].url
			: location.pathname.split("/").pop();

	useEffect(() => {}, [activeTab]);

	return (
		<div className="tabs-container">
			<div className="tab-buttons">
				{tabs.map((tab, index) => (
					<Link to={`/subjects/${tab.url}`} key={tab.title}>
						<button
							key={index}
							className={activeTab === tab.url ? "active" : ""}
						>
							{tab.title}
						</button>
					</Link>
				))}
			</div>
			<div className="tab-content" style={{ margin: "0px" }}>
				{tabs.map((tab, index) => (
					<div
						key={index}
						className={`tab-pane ${activeTab === tab.url ? "active" : ""}`}
					>
						{tab.content}
					</div>
				))}
			</div>
		</div>
	);
}

export default SubjectsPageComponent;
